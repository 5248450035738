const state = {
  // Table
  page: 1,
  pageSize: 10,
  where: {},
  // Rigging Jobs
  whereRiggingJobs: {},
  riggingJobs: [],
  numberRiggingJobs: null,
  currentRiggingJob: {},
  currentRiggingJobParts: {},
  currentStay: {},
  currentStayPart: {},
  // Rigging Items
  whereItems: {},

  // List
  wireSizes: [
    { label: "2mm", size: 2 },
    { label: "2.4mm 3/32", size: 2.4 },
    { label: "2.5mm", size: 2.5 },
    { label: "3mm", size: 3 },
    { label: "3.2mm 1/8", size: 3.2 },
    { label: "3.5mm", size: 3.5 },
    { label: "4mm 5/32", size: 4 },
    { label: "4.8mm 3/16", size: 4.8 },
    { label: "5mm", size: 5 },
    { label: "5.5mm 7/32", size: 5.5 },
    { label: "6mm", size: 6 },
    { label: "6.4mm 1/4", size: 6.4 },
    { label: "7mm", size: 7 },
    { label: "7.2mm 9/32", size: 7.2 },
    { label: "8mm 5/16", size: 8 },
    { label: "9mm", size: 9 },
    { label: "9.5mm 3/8", size: 9.5 },
    { label: "10mm", size: 10 },
    { label: "11mm 7/16", size: 11 },
    { label: "12mm", size: 12 },
    { label: "12.7mm 1/2", size: 12.7 },
    { label: "14mm", size: 14 },
    { label: "14.3mm 9/16", size: 14.3 },
    { label: "15.9mm 5/8", size: 15.9 },
    { label: "22mm 7/8", size: 22 },
    { label: '26mm 1"', size: 26 },
  ],

  allItems: [],
  brandList: [],
  typeList: [],
  priceSheetList: [],

  riggingItems: [],
  numberRiggingItems: null,
  currentRiggingItem: {},
};

const getters = {
  allRiggingJobs: (state) => state.riggingJobs,
  numberRiggingJobs: (state) => state.numberRiggingJobs,

  currentRiggingJob: (state) => state.currentRiggingJob,
  currentRiggingJobParts: (state) => state.currentRiggingJobParts,

  currentStay: (state) => state.currentStay,

  currentStayPart: (state) => state.currentStayPart,

  wireSizes: (state) => state.wireSizes,
  allItems: (state) => state.allItems,

  brandList: (state) => state.brandList,
  typeList: (state) => state.typeList,
  priceSheetList: (state) => state.priceSheetList,

  allRiggingItems: (state) => {
    return state.riggingItems;
  },
  numberRiggingItems: (state) => {
    return state.numberRiggingItems;
  },
  currentRiggingItem: (state) => state.currentRiggingItem,
};

const actions = {
  // Set Page used in pageJobs
  async setPage({ commit }, page) {
    commit("setPage", page);
  },
  // - Rigging Jobs -
  // Get Contacts for page of table
  async pageRiggingJobs({ commit }) {
    const response = await this.$axios.post(
      "/api/v1/rigging/jobs/findandcountall?offset=" +
        (state.page - 1) * state.pageSize +
        "&limit=" +
        state.pageSize,
      { where: state.whereRiggingJobs }
    );
    if (response.data.success) {
      commit("setRiggingJobs", response.data.data);
    } else {
      console.log("No Rigging Jobs");
      console.log(response.data.msg);
    }
  },
  // Create
  async createRiggingJob({ commit }, job) {
    const response = await this.$axios.post("/api/v1/rigging/job", job);
    if (response.data.success) {
      commit("setCurrentRiggingJob", response.data.data);
      commit("addRiggingJob");
      return response.data.data.id;
    } else {
      console.log("Create Rigging Job Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getRiggingJob({ commit }, id) {
    const response = await this.$axios.get("/api/v1/rigging/job/" + id);
    if (response.data.success) {
      commit("setCurrentRiggingJob", response.data.data);
      return true;
    } else {
      console.log("Get Rigging Job Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentRiggingJob", {});
      return false;
    }
  },
  // Update
  async updateRiggingJob({ commit, state }, jobUpdate) {
    const response = await this.$axios.put(
      "/api/v1/rigging/job/" + state.currentRiggingJob.id,
      state.currentRiggingJob
    );
    if (response.data.success) {
      await commit("setCurrentRiggingJob", jobUpdate);
      await commit("updateRiggingJob");
      return true;
    } else {
      console.log("Update Rigging Job Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Delete
  async deleteRiggingJob({ commit }, jobId) {
    const response = await this.$axios.delete("/api/v1/rigging/job/" + jobId);
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeRiggingJob", jobId);
    } else {
      console.log("Delete Rigging Job Error");
      console.log(response.data.msg);
    }
  },
  // List Job Parts
  async getRiggingJobParts({ commit }, id) {
    const response = await this.$axios.get("/api/v1/rigging/list/" + id);
    if (response.data.success) {
      commit("setCurrentRiggingJobParts", response.data.data);
      return true;
    } else {
      console.log("Get Rigging Job Parts Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentRiggingJobParts", {});
      return false;
    }
  },

  // - Stay -
  // Create
  async createStay({ commit }, stay) {
    const response = await this.$axios.post("/api/v1/rigging/stay", stay);
    if (response.data.success) {
      // Add a parts to the newly create stay
      response.data.data.parts = [];
      commit("setCurrentStay", response.data.data);
      commit("addStay");
      return response.data.data.id;
    } else {
      console.log("Create Stay Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getStay({ commit }, id) {
    const response = await this.$axios.get("/api/v1/rigging/stay/" + id);
    if (response.data.success) {
      commit("setCurrentStay", response.data.data);
      return true;
    } else {
      console.log("Get Stay Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentStay", {});
      return false;
    }
  },
  // Update
  async updateStay({ commit, state }) {
    const response = await this.$axios.put(
      "/api/v1/rigging/stay/" + state.currentStay.id,
      state.currentStay
    );
    if (response.data.success) {
      await this.dispatch("rigging/getStay", state.currentStay.id);
      await commit("updateStay");
      return true;
    } else {
      console.log("Update Stay Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Delete
  async deleteStay({ commit }, stayId) {
    const response = await this.$axios.delete("/api/v1/rigging/stay/" + stayId);
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeStay", stayId);
    } else {
      console.log("Delete Stay Error");
      console.log(response.data.msg);
    }
  },

  // - Stay Part -
  // Create
  async createStayPart({ commit }, stay) {
    console.log("createStayPart");
    const response = await this.$axios.post("/api/v1/rigging/part", stay);
    if (response.data.success) {
      // The current stay part needs an id before we can add the part to the list of parts on the stay.
      commit("setCurrentStayPart", response.data.data);
      commit("addStayPart");
      return response.data.data.id;
    } else {
      console.log("Create Stay Part Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getStayPart({ commit }, id) {
    const response = await this.$axios.get("/api/v1/rigging/part/" + id);
    if (response.data.success) {
      commit("setCurrentStayPart", response.data.data);
      return true;
    } else {
      console.log("Get Part Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentStayPart", {});
      return false;
    }
  },
  // Update
  async updateStayPart({ commit, state }) {
    const response = await this.$axios.put(
      "/api/v1/rigging/part/" + state.currentStayPart.id,
      state.currentStayPart
    );
    if (response.data.success) {
      await commit("updateStayPart");
      return true;
    } else {
      console.log("Update Part Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Delete
  async deleteStayPart({ commit }, stayId) {
    const response = await this.$axios.delete("/api/v1/rigging/part/" + stayId);
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeStayPart", stayId);
    } else {
      console.log("Delete Part Error");
      console.log(response.data.msg);
    }
  },

  // - List -
  // Read - Part and Wire
  async getItems({ commit }) {
    const response = await this.$axios.get("/api/v1/rigging/items");
    if (response.data.success) {
      commit("setItems", response.data.data);
      return true;
    } else {
      console.log("Get Items Error");
      console.log(response.data.msg);
      commit("setItems", []);
      return false;
    }
  },
  // Brand
  async getBrandList({ commit }) {
    const response = await this.$axios.get(
      "/api/v1/rigging/distinctpricelist/" + "brand"
    );
    if (response.data.success) {
      commit("setBrandList", response.data.data);
      return true;
    } else {
      console.log("Get Brand List Error");
      console.log(response.data.msg);
      commit("setBrandList", {});
      return false;
    }
  },
  // Type
  async getTypeList({ commit }) {
    const response = await this.$axios.get(
      "/api/v1/rigging/distinctpricelist/" + "type"
    );
    if (response.data.success) {
      commit("setTypeList", response.data.data);
      return true;
    } else {
      console.log("Get Type List Error");
      console.log(response.data.msg);
      commit("setTypeList", {});
      return false;
    }
  },
  // Price List
  async getpriceSheetList({ commit }) {
    const response = await this.$axios.get(
      "/api/v1/rigging/distinctpricelist/" + "priceList"
    );
    if (response.data.success) {
      commit("setpriceSheetList", response.data.data);
      return true;
    } else {
      console.log("Get Price Sheet List Error");
      console.log(response.data.msg);
      commit("setpriceSheetList", {});
      return false;
    }
  },

  // - Rigging Items (Settings) -
  // Get Rigging Items for page of table
  async pageRiggingItems({ commit }) {
    const response = await this.$axios.post(
      "/api/v1/rigging/findandcountall?offset=" +
        (state.page - 1) * state.pageSize +
        "&limit=" +
        state.pageSize,
      { where: state.whereItems }
    );
    if (response.data.success) {
      commit("pageRiggingItems", response.data.data);
    } else {
      console.log("No Items");
      console.log(response.data.msg);
    }
  },
  // Create
  async createRiggingItem({ commit }, item) {
    const response = await this.$axios.post("/api/v1/rigging/item", item);
    if (response.data.success) {
      commit("addRiggingItem", response.data.data.id);
      return response.data.data.id;
    } else {
      console.log("Create Rigging Item Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getRiggingItem({ commit }, id) {
    const response = await this.$axios.get("/api/v1/rigging/item/" + id);
    if (response.data.success) {
      commit("setCurrentRiggingItem", response.data.data);
      return true;
    } else {
      console.log("Get Rigging Item Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentRiggingItem", {});
      return false;
    }
  },
  // Update
  async updateRiggingItem({ commit, state }) {
    const response = await this.$axios.put(
      "/api/v1/rigging/item/" + state.currentRiggingItem.id,
      state.currentRiggingItem
    );
    if (response.data.success) {
      await commit("updateRiggingItem");
      return true;
    } else {
      console.log("Update Rigging Item Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Delete
  async deleteRiggingItem({ commit }, itemId) {
    const response = await this.$axios.delete("/api/v1/rigging/item/" + itemId);
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeRiggingItem", itemId);
    } else {
      console.log("Delete Rigging Item Error");
      console.log(response.data.msg);
    }
  },
  // Filter - Add/Delete/Clear
  async setWhereItems({ commit }, whereItems) {
    commit("setWhereItems", whereItems);
  },
  async removeFromWhereItems({ commit }, key) {
    commit("removeFromWhereItems", key);
  },
};

const mutations = {
  setPage: (state, page) => {
    state.page = page;
  },
  // - Rigging Job -
  setRiggingJobs: (state, response) => (
    (state.riggingJobs = response.rows),
    (state.numberRiggingJobs = response.count)
  ),
  setCurrentRiggingJob: (state, response) =>
    (state.currentRiggingJob = response),
  addRiggingJob: (state) => {
    state.riggingJobs.unshift(state.currentRiggingJob);
  },
  updateRiggingJob: (state) => {
    const index = state.riggingJobs.findIndex(
      (i) => i.id === state.currentRiggingJob.id
    );
    if (index !== -1) {
      state.riggingJobs.splice(index, 1, state.currentRiggingJob);
    }
  },
  removeRiggingJob: (state, id) => (
    (state.currentRiggingJob = {}),
    (state.riggingJobs = state.riggingJobs.filter((job) => job.id !== id))
  ),
  setCurrentRiggingJobParts: (state, response) =>
    (state.currentRiggingJobParts = response),

  // - Stay -
  setCurrentStay: (state, response) => {
    state.currentStay = response;
  },
  addStay: (state) => {
    state.currentRiggingJob.stays.unshift(state.currentStay);
  },
  updateStay: (state) => {
    const index = state.currentRiggingJob.stays.findIndex(
      (i) => i.id === state.currentStay.id
    );
    state.currentStay.cost = calcStayTotal(state);
    if (index !== -1) {
      state.currentRiggingJob.stays.splice(index, 1, state.currentStay);
    }
  },
  removeStay: (state, id) => (
    (state.currentStay = {}),
    (state.currentRiggingJob.stays = state.currentRiggingJob.stays.filter(
      (stay) => stay.id !== id
    ))
  ),

  // - Stay Items -
  setCurrentStayPart: (state, response) => {
    state.currentStayPart = response;
  },
  addStayPart: (state) => {
    state.currentStay.parts.unshift(state.currentStayPart);
    state.currentStay.cost = calcStayTotal(state);
  },
  updateStayPart: (state) => {
    const index = state.currentStay.parts.findIndex(
      (i) => i.id === state.currentStayPart.id
    );
    if (index !== -1) {
      state.currentStay.parts.splice(index, 1, state.currentStayPart);
      state.currentStay.cost = calcStayTotal(state);
    }
  },
  removeStayPart: (state, id) => (
    (state.currentStayPart = {}),
    (state.currentStay.parts = state.currentStay.parts.filter(
      (part) => part.id !== id
    ))
    // Fix Me Recalc Cost
  ),

  // Items List (Part / Wire)
  setItems: (state, response) => {
    state.allItems = response;
  },
  // Brand List
  setBrandList: (state, response) => {
    state.brandList = response;
  },
  // Type List
  setTypeList: (state, response) => {
    state.typeList = response;
  },
  // Type List
  setpriceSheetList: (state, response) => {
    state.priceSheetList = response;
  },

  // - Rigging Items (Settings) -
  pageRiggingItems: (state, response) => (
    (state.riggingItems = response.rows),
    (state.numberRiggingItems = response.count)
  ),
  setCurrentRiggingItem: (state, response) => {
    state.currentRiggingItem = response;
  },
  addRiggingItem: (state, id) => {
    (state.currentRiggingItem.id = id),
      state.riggingItems.unshift(state.currentRiggingItem);
  },
  updateRiggingItem: (state) => {
    const index = state.riggingItems.findIndex(
      (i) => i.id === state.currentRiggingItem.id
    );
    if (index !== -1) {
      state.riggingItems.splice(index, 1, state.currentRiggingItem);
    }
  },
  removeRiggingItem: (state, id) =>
    (state.riggingItems = state.riggingItems.filter((item) => item.id !== id)),
  setWhereItems: (state, whereItems) => {
    var key = Object.keys(whereItems)[0];
    var val = whereItems[key];
    state.whereItems = Object.assign(state.whereItems, { [key]: val });
  },
  removeFromWhereItems: (state, key) => {
    delete state.whereItems[key];
  },
};

function calcStayTotal(state) {
  var totalCost = state.currentStay.parts.reduce(
    (sum, { cost, qty }) => sum + Number(cost) * qty,
    0
  );
  return totalCost.toFixed(2);
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
