<template>
  <div>
    <b-alert
      show
      :variant="notification.type"
      dismissible
      @dismissed="dismissedAction"
      >{{ notification.message }}</b-alert
    >
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["notification"],
  data() {
    return {
      timeout: null,
    };
  },
  created() {
    if (this.notification.timeout) {
      this.timeout = setTimeout(() => {
        this.removeNotification(this.notification);
      }, 3000);
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapActions({ removeNotification: "notifications/removeNotification" }),
    dismissedAction() {
      this.removeNotification(this.notification);
    },
  },
};
</script>

<style></style>
