import Vuex from "vuex";
import Vue from "vue";
import auth from "./modules/auth";
import settings from "./modules/settings";
import notifications from "./modules/notifications";
import quotedata from "./modules/quotedata";
import quotetext from "./modules/quotetext";
import quotes from "./modules/quotes";
import quoteItem from "./modules/quoteItem";
import contacts from "./modules/contacts";
import jobs from "./modules/jobs";
import rolls from "./modules/rolls";
import rigging from "./modules/rigging";
import pricing from "./modules/pricing";

// Load Vuex
Vue.use(Vuex);

import { axios } from "@/plugins/axios";

const axiosPlugin = (store) => {
  store.$axios = axios;
};

// Create store
export default new Vuex.Store({
  modules: {
    auth,
    settings,
    notifications,
    quotedata,
    quotetext,
    quotes,
    quoteItem,
    contacts,
    jobs,
    rolls,
    rigging,
    pricing,
  },
  plugins: [axiosPlugin],
});
