import router from "../../router";
import jwt_decode from "jwt-decode";

const state = {
  isLoggedIn: null,
  token: null,
  refreshToken: null,
  userName: null,
  refreshTask: null,
};

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  getToken: (state) => state.token,
  getRefreshToken: (state) => state.refreshToken,
  getUserName: (state) => state.userName,
};

const actions = {
  // Login
  async login({ commit, dispatch }, response) {
    await commit("login", response);
    dispatch("autoRefresh");
    dispatch(
      "notifications/addNotification",
      {
        type: "success",
        message: `Hello ${state.userName}`,
        timeout: 3000,
      },
      { root: true }
    );
  },
  // Logout
  logout({ commit }) {
    commit("logout");
    router.push({ path: "/" }).catch(() => {});
  },
  // Auto Refresh set timer
  async autoRefresh({ getters, commit, dispatch }) {
    console.log("autoRefresh");
    const { exp } = jwt_decode(getters.getToken);
    const now = Date.now() / 1000; // exp is represented in seconds since epoch
    let timeUntilRefresh = exp - now;
    timeUntilRefresh -= 1 * 60; // Refresh 1 minutes before it expires
    const refreshTask = setTimeout(
      () => dispatch("refreshTokens"),
      timeUntilRefresh * 1000
    );
    commit("refreshTask", refreshTask); // In case you want to cancel this task on logout
  },
  // Refresh Tokens by sending request to server
  async refreshTokens({ dispatch }) {
    console.log("refreshTokens");
    const response = await this.$axios.get("/api/refreshtokens");
    if (response.data.success) {
      dispatch("autoRefresh");
    } else {
      dispatch("logout");
      router
        .push({ path: "/login", query: { redirect: router.currentRoute.path } })
        .catch(() => {});
    }
  },
  async testToken({ getters, commit, state, dispatch }) {
    // Get token from store
    var token = await getters.getToken;
    // No token in store
    if (!token) {
      // No token in local storage
      if (!localStorage.getItem("token")) {
        console.log("localStorage does not have a token item!");
        return false;
      }
      var storedToken = await localStorage.getItem("token");
      commit("setToken", storedToken);
      commit("setRefreshToken", localStorage.getItem("refreshToken"));
      commit("setUserName", localStorage.getItem("userName"));
      commit("settings/setLoftName", localStorage.getItem("loftName"), {
        root: true,
      });
      token = storedToken;
      state.isLoggedIn = true; // User might have invalid tokens
    }
    // Has token expired
    var tokenExp = new Date(jwt_decode(token).exp * 1000);
    var currentTime = new Date(Math.floor(Date.now()));
    if (tokenExp < currentTime) {
      // Token has expired, refresh token
      await dispatch("refreshTokens");
      return true;
    }
    // Is auto refresh running
    if (!state.refreshTask) {
      await dispatch("autoRefresh");
    }
    return true;
  },
  async getToken({ dispatch, getters }) {
    var token = await getters.getToken;
    var tokenExp = new Date(jwt_decode(token).exp * 1000);
    if (tokenExp < new Date(Math.floor(Date.now()))) {
      await dispatch("refreshTokens");
      return await getters.getToken;
    }
    return token;
  },
  async logTokens() {
    if (localStorage.getItem("token")) {
      console.log("token - " + localStorage.getItem("token"));
      console.log("refreshToken - " + localStorage.getItem("refreshToken"));
      var tokenExp = new Date(
        jwt_decode(localStorage.getItem("token")).exp * 1000
      );
      var currentTime = new Date(Math.floor(Date.now()));
      let timeUntilRefresh =
        tokenExp.valueOf() / 1000 - currentTime.valueOf() / 1000;
      console.log(
        "timeUntilRefresh : " + Math.floor(timeUntilRefresh) + " Seconds"
      );
      console.log(
        "minsUntilRefresh : " + (timeUntilRefresh / 60).toFixed(2) + " minutes"
      );
    } else {
      console.log("localStorage does not have a token item!");
    }
  },
};

const mutations = {
  login(state, data) {
    state.isLoggedIn = true;

    state.token = data.token;
    state.refreshToken = data.refreshToken;
    state.userName = data.user.firstName + " " + data.user.lastName;
    this.dispatch("settings/setLoftName", data.user.loftName);

    localStorage.setItem("token", state.token);
    localStorage.setItem("refreshToken", state.refreshToken);
    localStorage.setItem("userName", state.userName);
    localStorage.setItem("loftName", data.user.loftName);
  },
  logout(state) {
    state.isLoggedIn = false;

    state.token = null;
    state.refreshToken = null;
    state.refreshTask = null;
    state.userName = null;
    this.dispatch("settings/setLoftName", null);

    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("loftName");
  },
  refreshTask(state, data) {
    state.refreshTask = data;
  },
  updateTokens(state, data) {
    state.token = data.token;
    state.refreshToken = data.refreshToken;
    localStorage.setItem("token", state.token);
    localStorage.setItem("refreshToken", state.refreshToken);
  },
  setToken(state, token) {
    state.token = token;
    localStorage.setItem("token", state.token);
  },
  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
    localStorage.setItem("refreshToken", state.refreshToken);
  },
  setUserName(state, data) {
    state.userName = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
