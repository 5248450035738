const state = {
  // Quote Item
  currentQuoteItem: {},
};

const getters = {
  currentQuoteItem: (state) => state.currentQuoteItem,
};

const actions = {
  // Create
  async createQuoteItem({ commit }, quoteItem) {
    const response = await this.$axios.post(
      "/api/v1/quotes/quote/" + quoteItem.quoteId + "/quoteitem",
      quoteItem
    );
    if (response.data.success) {
      commit("setCurrentQuoteItem", response.data.data);
      return response.data.data.id;
    } else {
      console.log("Create Quote Item Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getQuoteItem({ commit }, id) {
    const response = await this.$axios.get("/api/v1/quotes/quoteitem/" + id);
    if (response.data.success) {
      commit("setCurrentQuoteItem", response.data.data);
      return true;
    } else {
      console.log("Get Quote Item Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentQuoteItem", {});
      return false;
    }
  },
  // Update
  async updateQuoteItem({ commit }, updateItem) {
    const response = await this.$axios.put(
      "/api/v1/quotes/quoteitem/" + state.currentQuoteItem.id,
      state.currentQuoteItem
    );
    if (response.data.success) {
      await commit("setCurrentQuoteItem", updateItem);
      //await commit('updateQuoteItem')
      return true;
    } else {
      console.log("Update Quote Item Error");
      console.log(response.data.msg);
      return false;
    }
  },

  // Delete
  async deleteQuoteItem({ commit }, quoteItemId) {
    const response = await this.$axios.delete(
      "/api/v1/quotes/quoteitem/" + quoteItemId
    );
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeQuoteItem");
      commit("quotes/removeQuoteItem", quoteItemId, { root: true });
    } else {
      console.log("Delete Quote Error");
      console.log(response.data.msg);
    }
  },
};

const mutations = {
  // Quote Item
  setCurrentQuoteItem: (state, response) => (state.currentQuoteItem = response),
  addQuoteItem: (state) => {
    state.currentQuote.quoteItems.unshift(state.currentQuoteItem);
  },
  updateQuoteItem: (state) => {
    const index = state.currentQuote.quoteItems.findIndex(
      (i) => i.id === state.currentQuoteItem.id
    );
    if (index !== -1) {
      state.currentQuote.quoteItems.splice(index, 1, state.currentQuoteItem);
    }
  },
  removeQuoteItem: (state) => (state.currentQuoteItem = {}),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
