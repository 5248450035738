const state = {
  // Loft Settings
  loftQuoteSettings: {},
  loftQuotePaths: {},
  loftName: null,
};

const getters = {
  getLoftName: (state) => state.loftName,
  // Loft Settings
  getQuoteSettings: (state) => {
    return state.loftQuoteSettings;
  },
  getQuotePaths: (state) => {
    return state.loftQuotePaths;
  },
};

const actions = {
  setLoftName({ commit }, loftName) {
    commit("setLoftName", loftName);
  },
  // Loft Settings
  async getLoft({ commit }) {
    const response = await this.$axios.get("/api/v1/loft");
    if (response.data.success) {
      commit("setLoft", response.data.data);
    } else {
      console.log("Get Loft Error");
      console.log(response.data.msg);
    }
  },
  // Loft Settings
  async updateLoft({ state }) {
    const response = await this.$axios.put("/api/v1/loft", {
      loftName: state.loftName,
      quotePrefix: state.loftQuoteSettings.quotePrefix,
      quotePrefixZeros: state.loftQuoteSettings.quotePrefixZeros,
      quoteNumber: state.loftQuoteSettings.quoteNumber,
    });
    if (response.data.success) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.dispatch("notifications/addNotification", {
        type: "success",
        message: `Loft Data has been updated.`,
        timeout: 3000,
      });
    } else {
      console.log("Update Loft Error");
      console.log(response.data.msg);
    }
  },
  // Headers & Footers (Upload)
  async uploadFile({ commit }, data) {
    const response = await this.$axios.post(
      "/api/v1/loft/upload/" + data.column,
      data.formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data.success) {
      commit("setPath", response.data.data);
    } else {
      console.log("Upload File error!");
      console.log(response.data.msg);
    }
  },
  // Headers & Footers (Delete)
  async deleteFile({ commit }, column) {
    const response = await this.$axios.delete("/api/v1/loft/file/" + column);
    if (response.data.success) {
      console.log(response.data.msg);
      commit("clearPath", column);
    } else {
      console.log("Delete File error!");
      console.log(response.data.msg);
    }
  },
};

const mutations = {
  setLoftName(state, data) {
    state.loftName = data;
  },
  // Loft Settings
  setLoft(state, data) {
    (state.loftName = data.loftName),
      (state.loftQuoteSettings = {
        quotePrefix: data.quotePrefix,
        quotePrefixZeros: data.quotePrefixZeros,
        quoteNumber: data.quoteNumber,
      });
    state.loftQuotePaths = {
      quoteOverviewHeaderPath: data.quoteOverviewHeaderPath,
      quoteItemHeaderPath: data.quoteItemHeaderPath,
      quoteFooterPath: data.quoteFooterPath,
    };
  },
  // Headers & Footers
  setPath(state, data) {
    if (data.column == "QuoteOverviewHeader") {
      state.loftQuotePaths.quoteOverviewHeaderPath = data.path;
    }
    if (data.column == "QuoteItemHeader") {
      state.loftQuotePaths.quoteItemHeaderPath = data.path;
    }
    if (data.column == "QuoteFooter") {
      state.loftQuotePaths.quoteFooterPath = data.path;
    }
  },
  clearPath(state, column) {
    if (column == "QuoteOverviewHeader") {
      state.loftQuotePaths.quoteOverviewHeaderPath = "";
    }
    if (column == "QuoteItemHeader") {
      state.loftQuotePaths.quoteItemHeaderPath = "";
    }
    if (column == "QuoteFooter") {
      state.loftQuotePaths.quoteFooterPath = "";
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
