const state = {
  // Table
  page: 1,
  pageSize: 10,
  where: {},
  // Quote Data
  quoteData: [],
  numberQuoteData: null,
  currentQuoteData: null,
  fabricTypes: [],
};

const getters = {
  // Quote Data
  allQuoteData: (state) => {
    return state.quoteData;
  },
  numberQuoteData: (state) => {
    return state.numberQuoteData;
  },
  getCurrentQuoteData: (state) => {
    return state.currentQuoteData;
  },
  listFabricTypes: (state) => {
    return state.fabricTypes;
  },
};

const actions = {
  // Get Quote Data for page of table
  async pageQuoteData({ commit }) {
    const response = await this.$axios.post(
      "/api/v1/quotedata/findandcountall?offset=" +
        (state.page - 1) * state.pageSize +
        "&limit=" +
        state.pageSize,
      { where: state.where }
    );
    if (response.data.success) {
      commit("pageQuoteData", response.data.data);
    } else {
      console.log("No Items");
      console.log(response.data.msg);
    }
  },
  // Page used in pageQuoteData
  async setPage({ commit }, page) {
    commit("setPage", page);
  },
  // Create - upload Quote Data
  async createQuoteData({ commit }, data) {
    const response = await this.$axios.post(
      "/api/v1/quotedata/upload",
      data.formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data.success) {
      commit("addQuoteData", response.data.data);
    } else {
      console.log("Create Quote Data Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getQuoteData({ commit }, id) {
    const response = await this.$axios.get("/api/v1/quotedata/" + id);
    if (response.data.success) {
      commit("setCurrentQuoteData", response.data.data);
      return true;
    } else {
      console.log("Get Quote Data Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentQuoteData", {});
      return false;
    }
  },
  // Update
  async updateQuoteData({ commit }, quoteDataUpdate) {
    const response = await this.$axios.put(
      "/api/v1/quotedata/" + quoteDataUpdate.id,
      quoteDataUpdate.data
    );
    console.log(response);
    if (response.data.success) {
      await commit("updateQuoteData", quoteDataUpdate.data);
      return true;
    } else {
      console.log("Update Quote Data Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Delete
  async deleteQuoteData({ commit }, id) {
    const response = await this.$axios.delete("/api/v1/quotedata/" + id);
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeQuoteData", id);
    } else {
      console.log("Delete Quote Data Error");
      console.log(response.data.msg);
    }
  },

  // List
  async listQuoteData({ commit }, itemTypeFilter) {
    const response = await this.$axios.get(
      "/api/v1/quotedata/filter/" + itemTypeFilter
    );
    if (response.data.success) {
      commit("listQuoteData", response.data.data);
      return true;
    } else {
      console.log("List Quote Data Error - " + itemTypeFilter);
      console.log(response.data.msg);
      commit("listQuoteData", {});
      return false;
    }
  },
};

const mutations = {
  // Table
  setPage: (state, page) => {
    state.page = page;
  },
  // Quote Data
  pageQuoteData: (state, response) => (
    (state.quoteData = response.rows), (state.numberQuoteData = response.count)
  ),
  addQuoteData: (state, response) => {
    state.quoteData.push(response);
  },
  setCurrentQuoteData: (state, response) => (state.currentQuoteData = response),
  updateQuoteData: (state, updQuoteData) => {
    const index = state.quoteData.findIndex((i) => i.id === updQuoteData.id);
    if (index !== -1) {
      state.quoteData.splice(index, 1, updQuoteData);
    }
  },
  removeQuoteData: (state, id) =>
    (state.quoteData = state.quoteData.filter(
      (quoteData) => quoteData.id !== id
    )),
  listQuoteData: (state, response) => (
    (state.quoteData = response),
    (state.fabricTypes = [...new Set(response.map((i) => i.filter))]),
    state.fabricTypes.unshift("All")
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
