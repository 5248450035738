<template>
  <div>
    <p>Home</p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
