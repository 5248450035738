import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Bootstrap CSS
import { BootstrapVue } from "bootstrap-vue";
import "./styles/app.scss";
Vue.use(BootstrapVue);

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

// Axios
import VueAxios from "./plugins/axios";
Vue.use(VueAxios);

import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);

import VueClipboards from "vue-clipboards";
Vue.use(VueClipboards);

// Filters
//import moment from 'moment'
import dateformat from "dateformat";
Vue.filter("formatDate", function (value) {
  if (value) {
    //return moment(String(value)).format('DD/MM/YYYY')
    return dateformat(value, "dd/mm/yyyy");
  }
});

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    currencyDisplay: "narrowSymbol",
  });
  return formatter.format(value);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
