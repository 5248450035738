<template>
  <div id="app">
    <Navbar :key="$route.fullPath" />
    <div class="container" style="padding-top: 75px">
      <NotificationsList />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import NotificationsList from "./components/notifications/NotificationsList.vue";
export default {
  name: "App",
  components: {
    Navbar,
    NotificationsList,
  },
};
</script>

<style></style>
