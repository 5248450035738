const state = {
  // Table
  page: 1,
  pageSize: 10,
  pageSort: "createdAt",
  pageDir: "ASC",
  // Quotes
  whereQuotes: {},
  quotes: [],
  numberQuotes: null,
  currentQuote: {},
};

const getters = {
  allQuotes: (state) => state.quotes,
  numberQuotes: (state) => state.numberQuotes,
  currentQuote: (state) => state.currentQuote,
};

const actions = {
  // Set Page used in pageQuotes & pageBoats
  async setPage({ commit }, page) {
    commit("setPage", page);
  },
  async setPageSize({ commit }, pageSize) {
    commit("setPageSize", pageSize);
  },
  async setPageSort({ commit }, pageSort) {
    commit("setPageSort", pageSort);
  },
  async setPageDir({ commit }, pageDir) {
    commit("setPageDir", pageDir);
  },
  // - Quotes -
  // Get Quotes for page of table
  async pageQuotes({ commit }) {
    var urlParams =
      "?offset=" +
      (state.page - 1) * state.pageSize +
      "&limit=" +
      state.pageSize;
    if (state.pageSort) {
      urlParams += "&sortKey=" + state.pageSort + "&sortDir=" + state.pageDir;
    }
    const response = await this.$axios.post(
      "/api/v1/quotes/findandcountall" + urlParams,
      { where: state.whereQuotes }
    );
    if (response.data.success) {
      commit("pageQuotes", response.data.data);
    } else {
      console.log("No Quotes");
      console.log(response.data.msg);
    }
  },
  // Create
  async createQuote({ commit }, contact) {
    const response = await this.$axios.post("/api/v1/quotes/quote", contact);
    if (response.data.success) {
      commit("setCurrentQuote", response.data.data);
      commit("addQuote");
      return response.data.data.id;
    } else {
      console.log("Create Quote Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getQuote({ commit }, id) {
    const response = await this.$axios.get("/api/v1/quotes/quote/" + id);
    if (response.data.success) {
      commit("setCurrentQuote", response.data.data);
      return true;
    } else {
      console.log("Get Quote Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentQuote", {});
      return false;
    }
  },
  // Update
  async updateQuote({ commit }, updateQuote) {
    const response = await this.$axios.put(
      "/api/v1/quotes/quote/" + state.currentQuote.id,
      state.currentQuote
    );
    if (response.data.success) {
      await commit("setCurrentQuote", updateQuote);
      await commit("updateQuote");
      return true;
    } else {
      console.log("Update Quote Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Delete
  async deleteQuote({ commit }, quoteId) {
    const response = await this.$axios.delete(
      "/api/v1/quotes/quote/" + quoteId
    );

    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeQuote", quoteId);
    } else {
      console.log("Delete Quote Error");
      console.log(response.data.msg);
    }
  },
};

const mutations = {
  setPage: (state, page) => {
    state.page = page;
  },
  setPageSize: (state, pageSize) => {
    state.pageSize = pageSize;
  },
  setPageSort: (state, pageSort) => {
    state.pageSort = pageSort;
  },
  setPageDir: (state, pageDir) => {
    state.pageDir = pageDir;
  },
  pageQuotes: (state, response) => (
    (state.quotes = response.rows), (state.numberQuotes = response.count)
  ),
  pageBoats: (state, response) => (
    (state.boats = response.rows), (state.numberBoats = response.count)
  ),
  // Quotes
  setCurrentQuote: (state, response) => (state.currentQuote = response),
  addQuote: (state) => {
    state.quotes.unshift(state.currentQuote);
  },
  updateQuote: (state) => {
    const index = state.quotes.findIndex((i) => i.id === state.currentQuote.id);
    if (index !== -1) {
      state.quotes.splice(index, 1, state.currentQuote);
    }
  },
  removeQuote: (state, id) => (
    (state.currentQuote = {}),
    (state.quotes = state.quotes.filter((quote) => quote.id !== id))
  ),
  removeQuoteItem: (state, id) =>
    (state.currentQuote.quoteItems = state.currentQuote.quoteItems.filter(
      (quoteItem) => quoteItem.id !== id
    )),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
