const state = {
  fabrics: [
    {
      id: "1",
      sailType: "Mainsail",
      name: "308 OC",
      cut: "Cross Cut",
      displayName: "DP 308 OC Cross Cut Dacron",
      width: 1400,
      cost: 13.5,
      rate: 55.5,
    },
    {
      id: "2",
      sailType: "Mainsail",
      name: "348 OC",
      cut: "Cross Cut",
      displayName: "DP 348 OC Cross Cut Dacron",
      width: 1400,
      cost: 14.15,
      rate: 55.5,
    },
    {
      id: "3",
      sailType: "Mainsail",
      name: "398 OC",
      cut: "Cross Cut",
      displayName: "DP 398 OC Cross Cut Dacron",
      width: 1400,
      cost: 15,
      rate: 55.5,
    },
    {
      id: "4",
      sailType: "Mainsail",
      name: "448 OC",
      cut: "Cross Cut",
      displayName: "DP 448 OC Cross Cut Dacron",
      width: 1400,
      cost: 15.75,
      rate: 55.5,
    },
    {
      id: "5",
      sailType: "Mainsail",
      name: "211 CB (914)",
      cut: "Cross Cut",
      displayName: "DP 211 CB Cross Cut Dacron",
      width: 914,
      cost: 8.43,
      rate: 55.5,
    },
    {
      id: "6",
      sailType: "Mainsail",
      name: "241 CB (914)",
      cut: "Cross Cut",
      displayName: "DP 241 CB Cross Cut Dacron",
      width: 914,
      cost: 8.72,
      rate: 55.5,
    },
    {
      id: "7",
      sailType: "Mainsail",
      name: "211 CB",
      cut: "Cross Cut",
      displayName: "DP 211 CB Cross Cut Dacron",
      width: 1400,
      cost: 12.1,
      rate: 55.5,
    },
    {
      id: "8",
      sailType: "Mainsail",
      name: "241 CB",
      cut: "Cross Cut",
      displayName: "DP 241 CB Cross Cut Dacron",
      width: 1400,
      cost: 12.97,
      rate: 55.5,
    },
    {
      id: "9",
      sailType: "Mainsail",
      name: "271 CB",
      cut: "Cross Cut",
      displayName: "DP 271 CB Cross Cut Dacron",
      width: 1400,
      cost: 14.3,
      rate: 55.5,
    },
    {
      id: "10",
      sailType: "Mainsail",
      name: "301 CB",
      cut: "Cross Cut",
      displayName: "DP 301 CB Cross Cut Dacron",
      width: 1400,
      cost: 15.2,
      rate: 55.5,
    },
    {
      id: "11",
      sailType: "Mainsail",
      name: "351 CB",
      cut: "Cross Cut",
      displayName: "DP 351 CB Cross Cut Dacron",
      width: 1400,
      cost: 16.1,
      rate: 55.5,
    },
    {
      id: "12",
      sailType: "Mainsail",
      name: "441 CB",
      cut: "Cross Cut",
      displayName: "DP 441 CB Cross Cut Dacron",
      width: 1400,
      cost: 19.5,
      rate: 55.5,
    },
    {
      id: "13",
      sailType: "Mainsail",
      name: "DCX-SL",
      cut: "Radial",
      displayName: "DCX-SL Radial Cruise Laminate",
      width: 1370,
      cost: 31.24,
      rate: 75.5,
    },
    {
      id: "14",
      sailType: "Mainsail",
      name: "DCX-L",
      cut: "Radial",
      displayName: "DCX-L Radial Cruise Laminate",
      width: 1500,
      cost: 37.21,
      rate: 75.5,
    },
    {
      id: "15",
      sailType: "Mainsail",
      name: "DCX-M",
      cut: "Radial",
      displayName: "DCX-M Radial Cruise Laminate",
      width: 1500,
      cost: 38.54,
      rate: 75.5,
    },
    {
      id: "16",
      sailType: "Mainsail",
      name: "DCX-H",
      cut: "Radial",
      displayName: "DCX-H Radial Cruise Laminate",
      width: 1500,
      cost: 41.84,
      rate: 75.5,
    },
    {
      id: "17",
      sailType: "Spinnaker",
      name: "SK75",
      cut: "Radial",
      displayName: "Radial SuperKote 75",
      width: 1500,
      cost: 14.98,
      rate: 15.5,
    },
    {
      id: "18",
      sailType: "Spinnaker",
      name: "SK150",
      cut: "Radial",
      displayName: "Radial SuperKote 150",
      width: 1500,
      cost: 14.36,
      rate: 15.5,
    },
    {
      id: "19",
      sailType: "Spinnaker",
      name: "SL210",
      cut: "Radial",
      displayName: "Radial StormLite 210",
      width: 1500,
      cost: 16.8,
      rate: 25,
    },
    {
      id: "20",
      sailType: "Spinnaker",
      name: "SL510",
      cut: "Radial",
      displayName: "Radial StormLite 510",
      width: 1400,
      cost: 21.62,
      rate: 25,
    },
    {
      id: "21",
      sailType: "Spinnaker",
      name: "Nylite 90",
      cut: "Radial",
      displayName: "Radial Nylite 90",
      width: 1500,
      cost: 9.53,
      rate: 15.5,
    },
    {
      id: "22",
      sailType: "Spinnaker",
      name: "Maxilte 150",
      cut: "Radial",
      displayName: "Radial Maxilte 150",
      width: 1500,
      cost: 12.37,
      rate: 15.5,
    },
    {
      id: "23",
      sailType: "Mainsail",
      name: "X Drive Black",
      cut: "X Drive",
      displayName: "X Drive Black",
      width: 1500,
      cost: 60,
      rate: 45,
    },
    {
      id: "24",
      sailType: "Headsail",
      name: "X Drive Black",
      cut: "X Drive",
      displayName: "X Drive Black",
      width: 1500,
      cost: 60,
      rate: 45,
    },
  ],
  battens: [
    { id: 0, text: "No Battens", numberOfBattens: 0 },
    { id: 1, text: "3 Full Battens", numberOfBattens: 3 },
    { id: 2, text: "4 Full Battens", numberOfBattens: 4 },
    { id: 3, text: "5 Full Battens", numberOfBattens: 5 },
    { id: 4, text: "6 Full Battens", numberOfBattens: 6 },
    { id: 5, text: "7 Full Battens", numberOfBattens: 7 },
  ],
  battenBoxes: [
    { id: 0, text: "No boxes", cost: 0 },
    { id: 1, text: "Reuse Boxes", cost: 0 },
    { id: 2, text: "Plastic Batten Boxes", cost: 20 },
    { id: 3, text: "M10 Plastic Batten Boxes", cost: 30 },
    { id: 4, text: "M10 Alloy Batten Boxes", cost: 35 },
    { id: 5, text: "M12 Alloy Batten Boxes", cost: 50 },
  ],
  reefs: [
    { id: 0, text: "No Reefs", numberOfReefs: 0 },
    { id: 1, text: "1 Reef", numberOfReefs: 1 },
    { id: 2, text: "2 Reefs", numberOfReefs: 2 },
    { id: 3, text: "3 Reefs", numberOfReefs: 3 },
    { id: 4, text: "4 Reefs", numberOfReefs: 4 },
  ],
};

const getters = {
  fabrics: (state) => state.fabrics,
  battens: (state) => state.battens,
  battenBoxes: (state) => state.battenBoxes,
  reefs: (state) => state.reefs,
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
