var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{attrs:{"fixed":"top","toggleable":"lg","type":"light","variant":"light"}},[_c('div',{staticClass:"container"},[_c('b-navbar-brand',[_c('i',{staticClass:"fas fa-cube"}),_vm._v(" SailQuoter")]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-nav-item',{class:{ active: _vm.$route.name === 'Home' },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Home ")])]}}])}),(_vm.isLoggedIn)?_c('b-nav-item-dropdown',{class:{
            active:
              _vm.$route.name === 'ContactsTable' ||
              _vm.$route.name === 'BoatsTable' ||
              _vm.$route.name === 'edit_contact' ||
              _vm.$route.name === 'edit_boat',
          },attrs:{"right":""}},[_c('template',{slot:"text"},[_vm._v(" Contacts ")]),_c('router-link',{attrs:{"to":"/contacts","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Contacts ")])]}}],null,false,423785101)}),_c('router-link',{attrs:{"to":"/contacts/boats","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Boats")])]}}],null,false,3038005141)})],2):_vm._e(),_c('router-link',{attrs:{"to":"/jobs","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [(_vm.isLoggedIn)?_c('b-nav-item',{class:{
              active:
                _vm.$route.name === 'JobsTable' || _vm.$route.name === 'edit_job',
            },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Jobs ")]):_vm._e()]}}])}),_c('router-link',{attrs:{"to":"/quotes","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [(_vm.isLoggedIn)?_c('b-nav-item',{class:{
              active: _vm.$route.name === 'QuotesTable',
            },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Quotes ")]):_vm._e()]}}])}),_c('router-link',{attrs:{"to":"/rolls","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [(_vm.isLoggedIn)?_c('b-nav-item',{class:{
              active: _vm.$route.name === 'RollsTable',
            },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Rolls ")]):_vm._e()]}}])}),_c('router-link',{attrs:{"to":"/riggingjobs","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [(_vm.isLoggedIn)?_c('b-nav-item',{class:{
              active: _vm.$route.name === 'RiggingJobsTable',
            },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Rigging ")]):_vm._e()]}}])})],1),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('router-link',{attrs:{"to":"/login","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [(!_vm.isLoggedIn)?_c('b-nav-item',{class:{ active: _vm.$route.name === 'login' },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Login ")]):_vm._e()]}}])}),(_vm.isLoggedIn)?_c('b-nav-item-dropdown',{class:{ active: _vm.$route.name === 'profile' },attrs:{"right":""}},[_c('template',{slot:"text"},[_vm._v(" "+_vm._s(_vm.userName)+" - "+_vm._s(_vm.loftName)+" ")]),_c('router-link',{attrs:{"to":"/profile","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Profile")])]}}],null,false,2198251765)}),_c('router-link',{attrs:{"to":"/settings","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Settings")])]}}],null,false,3406814267)}),_c('b-dropdown-divider'),_c('router-link',{attrs:{"to":"/authdebug","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Auth Debug")])]}}],null,false,4191770183)}),_c('b-dropdown-item',{on:{"click":_vm.logout}},[_vm._v("Logout")])],2):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }