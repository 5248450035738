const state = {
  // Table
  page: 1,
  pageSize: 10,
  pageSort: "createdAt",
  pageDir: "ASC",
  // Rolls
  whereRolls: {},
  rolls: [],
  numberRolls: null,
  currentRoll: {},
  // Roll Usage
  currentRollUsage: {},
  // List
  fabricList: [],
  fabricTypeList: [],
  locationList: [],
};

const getters = {
  pageSize: (state) => state.pageSize,
  whereRolls: (state) => state.whereRolls,
  allRolls: (state) => state.rolls,
  numberRolls: (state) => state.numberRolls,
  currentRoll: (state) => state.currentRoll,
  currentRollUsage: (state) => state.currentRollUsage,
  fabricList: (state) => state.fabricList,
  fabricTypeList: (state) => state.fabricTypeList,
  locationList: (state) => state.locationList,
};

const actions = {
  // Set Page used in pageRolls
  async setPage({ commit }, page) {
    commit("setPage", page);
  },
  async setPageSize({ commit }, pageSize) {
    commit("setPageSize", pageSize);
  },
  async setPageSort({ commit }, pageSort) {
    commit("setPageSort", pageSort);
  },
  async setPageDir({ commit }, pageDir) {
    commit("setPageDir", pageDir);
  },
  async setWhereRolls({ commit }, whereRolls) {
    commit("setWhereRolls", whereRolls);
  },
  async removeFromWhereRolls({ commit }, key) {
    commit("removeFromWhereRolls", key);
  },
  // - Rolls -
  // Get Rolls for page of table
  async pageRolls({ commit }) {
    var urlParams =
      "?offset=" +
      (state.page - 1) * state.pageSize +
      "&limit=" +
      state.pageSize;
    if (state.pageSort) {
      urlParams += "&sortKey=" + state.pageSort + "&sortDir=" + state.pageDir;
    }
    const response = await this.$axios.post(
      "/api/v1/rolls/findandcountall" + urlParams,
      { where: state.whereRolls }
    );
    if (response.data.success) {
      commit("setRolls", response.data.data);
    } else {
      console.log("No Rolls");
      console.log(response.data.msg);
    }
  },
  // Create
  async createRoll({ commit }, roll) {
    const response = await this.$axios.post("/api/v1/rolls/roll", roll);
    if (response.data.success) {
      commit("setCurrentRoll", response.data.data);
      commit("addRoll");
      return response.data.data.id;
    } else {
      console.log("Create Roll Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getRoll({ commit }, id) {
    const response = await this.$axios.get("/api/v1/rolls/roll/" + id);
    if (response.data.success) {
      commit("setCurrentRoll", response.data.data);
      return true;
    } else {
      console.log("Get Roll Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentRoll", {});
      return false;
    }
  },
  // Update
  async updateRoll({ commit, state }) {
    const response = await this.$axios.put(
      "/api/v1/rolls/roll/" + state.currentRoll.id,
      state.currentRoll
    );
    if (response.data.success) {
      await commit("setCurrentRoll", response.data.data[1][0]);
      // rollUsages is not returned.
      await commit("updateRoll");
      return true;
    } else {
      console.log("Update Roll Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Update in background as updateRoll replace roll with the response without rollUsages.
  async updateRollAmountLeft({ state }) {
    const response = await this.$axios.put(
      "/api/v1/rolls/roll/" + state.currentRoll.id,
      state.currentRoll
    );
    if (!response.data.success) {
      console.log("Update Roll Error");
    }
  },
  // Delete
  async deleteRoll({ commit }, rollId) {
    const response = await this.$axios.delete("/api/v1/rolls/roll/" + rollId);
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeRoll", rollId);
    } else {
      console.log("Delete Roll Error");
      console.log(response.data.msg);
    }
  },
  // - Roll Usage -
  // Create
  async createRollUsage({ commit }, rollUsage) {
    const response = await this.$axios.post("/api/v1/rolls/usage", rollUsage);
    if (response.data.success) {
      await commit("addRollUsage", response.data.data);
      return true;
    } else {
      console.log("Create Roll Usage Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Read
  async getRollUsage({ commit }, id) {
    const response = await this.$axios.get("/api/v1/rolls/usage/" + id);
    if (response.data.success) {
      commit("setCurrentRollUsage", response.data.data);
      return true;
    } else {
      console.log("Get Roll Usage Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentRollUsage", {});
      return false;
    }
  },
  // Update
  async updateRollUsage({ commit, state }) {
    const response = await this.$axios.put(
      "/api/v1/rolls/usage/" + state.currentRollUsage.id,
      state.currentRollUsage
    );
    if (response.data.success) {
      await commit("setCurrentRollUsage", response.data.data[1][0]);
      await commit("updateRollUsage");
      return true;
    } else {
      console.log("Update Roll Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Delete
  async deleteRollUsage({ commit }, rollUsageId) {
    const response = await this.$axios.delete(
      "/api/v1/rolls/usage/" + rollUsageId
    );
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeRollUsage", rollUsageId);
    } else {
      console.log("Delete Roll Usage Error");
      console.log(response.data.msg);
    }
  },
  // - List-
  // Fabric
  async getFabricList({ commit }) {
    const response = await this.$axios.get(
      "/api/v1/rolls/distinctlist/" + "fabric"
    );
    if (response.data.success) {
      commit("setFabricList", response.data.data);
      return true;
    } else {
      console.log("Get Fabric List Error");
      console.log(response.data.msg);
      commit("setFabricList", {});
      return false;
    }
  },
  // Fabric Type
  async getFabricTypeList({ commit }) {
    const response = await this.$axios.get(
      "/api/v1/rolls/distinctlist/" + "fabricType"
    );
    if (response.data.success) {
      commit("setFabricTypeList", response.data.data);
      return true;
    } else {
      console.log("Get Fabric Type List Error");
      console.log(response.data.msg);
      commit("setFabricTypeList", {});
      return false;
    }
  },
  // Location
  async getLocationList({ commit }) {
    const response = await this.$axios.get(
      "/api/v1/rolls/distinctlist/" + "location"
    );
    if (response.data.success) {
      commit("setLocationList", response.data.data);
      return true;
    } else {
      console.log("Get Fabric Type List Error");
      console.log(response.data.msg);
      commit("setLocationList", {});
      return false;
    }
  },
};

const mutations = {
  setPage: (state, page) => {
    state.page = page;
  },
  setPageSize: (state, pageSize) => {
    state.pageSize = pageSize;
  },
  setPageSort: (state, pageSort) => {
    state.pageSort = pageSort;
  },
  setPageDir: (state, pageDir) => {
    state.pageDir = pageDir;
  },
  setWhereRolls: (state, whereRolls) => {
    var key = Object.keys(whereRolls)[0];
    var val = whereRolls[key];
    state.whereRolls = Object.assign(state.whereRolls, { [key]: val });
  },
  removeFromWhereRolls: (state, key) => {
    delete state.whereRolls[key];
  },
  // Rolls
  setRolls: (state, response) => (
    (state.rolls = response.rows), (state.numberRolls = response.count)
  ),
  setCurrentRoll: (state, response) => (state.currentRoll = response),
  addRoll: (state) => {
    state.rolls.unshift(state.currentRoll);
  },
  updateRoll: (state) => {
    const index = state.rolls.findIndex((i) => i.id === state.currentRoll.id);
    if (index !== -1) {
      state.rolls.splice(index, 1, state.currentRoll);
    }
  },
  removeRoll: (state, id) => (
    (state.currentRoll = {}),
    (state.rolls = state.rolls.filter((roll) => roll.id !== id))
  ),
  // Roll Usage
  setCurrentRollUsage: (state, response) => (state.currentRollUsage = response),
  addRollUsage: (state, response) => {
    state.currentRoll.rollUsages.unshift(response);
    state.currentRoll.amountLeft = calcAmountLeft(state);
    setAmountLeft(state);
  },
  updateRollUsage: (state) => {
    const index = state.currentRoll.rollUsages.findIndex(
      (i) => i.id === state.currentRollUsage.id
    );
    if (index !== -1) {
      state.currentRoll.rollUsages.splice(index, 1, state.currentRollUsage);
    }
    state.currentRoll.amountLeft = calcAmountLeft(state);
    setAmountLeft(state);
  },
  removeRollUsage: (state, id) => (
    (state.currentRollUsage = {}),
    (state.currentRoll.rollUsages = state.currentRoll.rollUsages.filter(
      (rollUsage) => rollUsage.id !== id
    )),
    (state.currentRoll.amountLeft = calcAmountLeft(state)),
    setAmountLeft(state)
  ),
  // List
  setFabricList: (state, response) => {
    state.fabricList = response;
  },
  setFabricTypeList: (state, response) => {
    state.fabricTypeList = response;
  },
  setLocationList: (state, response) => {
    state.locationList = response;
  },
};

function calcAmountLeft(state) {
  var rollLenght = Number(state.currentRoll.length);
  var totalUsed = state.currentRoll.rollUsages.reduce(
    (sum, { lengthUsed }) => sum + Number(lengthUsed),
    0
  );
  var amountLeft = rollLenght - totalUsed;
  return amountLeft.toFixed(2);
}

function setAmountLeft(state) {
  const index = state.rolls.findIndex((i) => i.id === state.currentRoll.id);
  state.rolls[index].amountLeft = state.currentRoll.amountLeft;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
