<template>
  <div class="notifications-list">
    <NotificationMessage
      v-for="notification in allNotifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationMessage from "../../components/notifications/NotificationMessage.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    NotificationMessage,
  },
  computed: {
    ...mapGetters({
      allNotifications: "notifications/allNotifications",
    }),
  },
};
</script>

<style></style>
