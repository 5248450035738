const state = {
  // Table
  page: 1,
  pageSize: 10,
  where: {},
  // Quote Text
  quoteText: [],
  numberQuoteText: null,
  currentQuoteText: null,
  terms: [],
  listQuoteTermTypes: [],
  includes: [],
  listQuoteIncludesTypes: [],
};

const getters = {
  // Quote Text
  allQuoteText: (state) => {
    return state.quoteText;
  },
  numberQuoteText: (state) => {
    return state.numberQuoteText;
  },
  getCurrentQuoteText: (state) => {
    return state.currentQuoteText;
  },
  listQuoteTerms: (state) => {
    return state.terms;
  },
  listQuoteTermTypes: (state) => {
    return state.listQuoteTermTypes;
  },
  listQuoteIncludes: (state) => {
    return state.includes;
  },
  listQuoteIncludesTypes: (state) => {
    return state.listQuoteIncludesTypes;
  },
};

const actions = {
  // Get Quote Text for page of table
  async pageQuoteText({ commit }) {
    const response = await this.$axios.post(
      "/api/v1/quotetext/findandcountall?offset=" +
        (state.page - 1) * state.pageSize +
        "&limit=" +
        state.pageSize,
      { where: state.where }
    );
    if (response.data.success) {
      commit("pageQuoteText", response.data.data);
    } else {
      console.log("No Items");
      console.log(response.data.msg);
    }
  },
  // Page used in pageTable
  async setPage({ commit }, page) {
    commit("setPage", page);
  },
  // Create - upload Quote Text
  async createQuoteText({ commit }, data) {
    const response = await this.$axios.post("/api/v1/quotetext/create", data);
    if (response.data.success) {
      commit("addQuoteText", response.data.data);
    } else {
      console.log("Create Quote Text Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getQuoteText({ commit }, id) {
    const response = await this.$axios.get("/api/v1/quotetext/" + id);
    if (response.data.success) {
      commit("setCurrentQuoteText", response.data.data);
      return true;
    } else {
      console.log("Get Quote Text Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentQuoteText", {});
      return false;
    }
  },
  // Update
  async updateQuoteText({ commit }, quoteTextUpdate) {
    const response = await this.$axios.put(
      "/api/v1/quotetext/" + quoteTextUpdate.id,
      quoteTextUpdate.data
    );
    console.log(response);
    if (response.data.success) {
      console.log(quoteTextUpdate);
      await commit("updateQuoteText", quoteTextUpdate.data);
      return true;
    } else {
      console.log("Update Quote Text Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Delete
  async deleteQuoteText({ commit }, id) {
    const response = await this.$axios.delete("/api/v1/quotetext/" + id);
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeQuoteText", id);
    } else {
      console.log("Delete Quote Text Error");
      console.log(response.data.msg);
    }
  },
  addFilter({ commit }, filter) {
    commit("setFilter", filter);
  },
  deleteFilter({ commit }, filter) {
    commit("removeFilter", filter);
  },
  clearFilter({ commit }) {
    commit("clearFilter");
  },

  // List
  async listQuoteText({ commit }, textTypeFilter) {
    const response = await this.$axios.get(
      "/api/v1/quotetext/filter/" + textTypeFilter
    );
    if (textTypeFilter === "Terms") {
      if (response.data.success) {
        commit("setQuoteTermsList", response.data.data);
        return true;
      } else {
        console.log("Get Quote Text Error - " + textTypeFilter);
        console.log(response.data.msg);
        commit("setQuoteTermsList", {});
        return false;
      }
    }
    if (textTypeFilter === "Includes") {
      if (response.data.success) {
        commit("setQuoteIncludesList", response.data.data);
        return true;
      } else {
        console.log("Get Quote Text Error - " + textTypeFilter);
        console.log(response.data.msg);
        commit("setQuoteIncludesList", {});
        return false;
      }
    }
  },
};

const mutations = {
  // Table
  setPage: (state, page) => {
    state.page = page;
  },
  // Where
  setFilter: (state, filter) => {
    var key = filter.condition;
    var value = filter.value;
    state.where[key] = value;
  },
  removeFilter: (state, filter) => {
    delete state.where[filter.condition];
  },
  clearFilter: (state) => {
    state.where = {};
  },
  // Quote Text
  pageQuoteText: (state, response) => (
    (state.quoteText = response.rows), (state.numberQuoteText = response.count)
  ),
  addQuoteText: (state, response) => {
    console.log(response);
    state.quoteText.push(response);
  },
  setCurrentQuoteText: (state, response) => (state.currentQuoteText = response),
  updateQuoteText: (state, updQuoteText) => {
    const index = state.quoteText.findIndex((i) => i.id === updQuoteText.id);
    if (index !== -1) {
      state.quoteText.splice(index, 1, updQuoteText);
    }
  },
  removeQuoteText: (state, id) =>
    (state.quoteText = state.quoteText.filter(
      (quoteText) => quoteText.id !== id
    )),
  setQuoteTermsList: (state, response) => (
    (state.terms = response),
    (state.listQuoteTermTypes = [
      ...new Set(response.map((i) => i.selectFilter)),
    ]),
    state.listQuoteTermTypes.unshift("All")
  ),
  setQuoteIncludesList: (state, response) => (
    (state.includes = response),
    (state.listQuoteIncludesTypes = [
      ...new Set(response.map((i) => i.selectFilter)),
    ]),
    state.listQuoteIncludesTypes.unshift("All")
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
