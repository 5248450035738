import Axios from "axios";
import store from "../store";

Axios.defaults.baseURL = process.env.VUE_APP_API;

Axios.interceptors.request.use(
  async (config) => {
    // When refreshtokens use current tokens
    if (config.url === "/api/refreshtokens") {
      config.headers["x-token"] = await store.getters["auth/getToken"];
      config.headers["x-refresh-token"] = await store.getters[
        "auth/getRefreshToken"
      ];
      return config;
    }
    // All request other then refreshtokens or login get a vaild token first
    if (config.url !== "/api/v1/users/login") {
      config.headers["x-token"] = await store.dispatch("auth/getToken");
      config.headers["x-refresh-token"] = await store.getters[
        "auth/getRefreshToken"
      ];
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  async (response) => {
    // When response is for refreshtokens save then new tokens
    if (response.config.url !== "/api/refreshtoken") {
      const token = response.headers["x-token"];
      const refreshToken = response.headers["x-refresh-token"];
      if (token) {
        await store.commit("auth/setToken", token);
      }
      if (refreshToken) {
        await store.commit("auth/setRefreshToken", refreshToken);
      }
    }
    return response;
  },
  async function (error) {
    if (401 === error.response.status) {
      // Unauthorized
      store.dispatch("notifications/addNotification", {
        type: "danger",
        timeout: 10000,
        message: `${error.response.data.msg}`,
      });
      return error.response;
    }
    return Promise.reject(error.response);
  }
);

export const axios = Axios;

export default {
  install(Vue) {
    Vue.prototype.$axios = Axios;
  },
};
