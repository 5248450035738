import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import store from "./store";

Vue.use(Router);

let router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  var testToken = await store.dispatch("auth/testToken");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!testToken) {
      next({
        name: "Login",
        query: { redirect: to.path },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (testToken) {
      next({
        name: "Home",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
