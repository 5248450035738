const state = {
  // Table
  page: 1,
  pageSize: 10,
  pageSort: "createdAt",
  pageDir: "ASC",
  // Contacts
  whereContacts: {},
  contacts: [],
  numberContacts: null,
  currentContact: {},
  contactList: [],
  // Boats
  whereBoats: {},
  boats: [],
  numberBoats: null,
  currentBoat: {},
  contactBoatList: [],
};

const getters = {
  pageSize: (state) => state.pageSize,
  allContacts: (state) => state.contacts,
  numberContacts: (state) => state.numberContacts,
  currentContact: (state) => state.currentContact,
  listContacts: (state) => state.contactList,
  allBoats: (state) => state.boats,
  numberBoats: (state) => state.numberBoats,
  currentBoat: (state) => state.currentBoat,
  listContactBoats: (state) => state.contactBoatList,
};

const actions = {
  // Set Page used in pageContacts & pageBoats
  async setPage({ commit }, page) {
    commit("setPage", page);
  },
  async setPageSize({ commit }, pageSize) {
    commit("setPageSize", pageSize);
  },
  async setPageSort({ commit }, pageSort) {
    commit("setPageSort", pageSort);
  },
  async setPageDir({ commit }, pageDir) {
    commit("setPageDir", pageDir);
  },
  /*
  async setWhereContacts({ commit }, whereContacts) {
    commit('setWhereContacts', whereContacts);
  },
  */
  // - Contacts -
  // Get Contacts for page of table
  async pageContacts({ commit }) {
    var urlParams =
      "?offset=" +
      (state.page - 1) * state.pageSize +
      "&limit=" +
      state.pageSize;
    if (state.pageSort) {
      urlParams += "&sortKey=" + state.pageSort + "&sortDir=" + state.pageDir;
    }
    const response = await this.$axios.post(
      "/api/v1/contacts/findandcountall" + urlParams,
      { where: state.whereContacts }
    );
    if (response.data.success) {
      commit("pageContacts", response.data.data);
    } else {
      console.log("No Contacts");
      console.log(response.data.msg);
    }
  },
  // Create
  async createContact({ commit }, contact) {
    const response = await this.$axios.post(
      "/api/v1/contacts/contact",
      contact
    );
    if (response.data.success) {
      commit("setCurrentContact", response.data.data);
      commit("addContact");
      return response.data.data.id;
    } else {
      console.log("Create Contact Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getContact({ commit }, id) {
    const response = await this.$axios.get("/api/v1/contacts/contact/" + id);
    if (response.data.success) {
      commit("setCurrentContact", response.data.data);
      return true;
    } else {
      console.log("Get Contact Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentContact", {});
      return false;
    }
  },
  // Update
  async updateContact({ commit }, updateContact) {
    const response = await this.$axios.put(
      "/api/v1/contacts/contact/" + state.currentContact.id,
      state.currentContact
    );
    if (response.data.success) {
      await commit("setCurrentContact", updateContact);
      await commit("updateContact");
      return true;
    } else {
      console.log("Update Contact Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Delete
  async deleteContact({ commit }, contactId) {
    const response = await this.$axios.delete(
      "/api/v1/contacts/contact/" + contactId
    );

    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeContact", contactId);
    } else {
      console.log("Delete Contact Error");
      console.log(response.data.msg);
    }
  },

  // - Boats -
  // Get Boats for page of table
  async pageBoats({ commit }) {
    const response = await this.$axios.post(
      "/api/v1/contacts/findandcountallboats?offset=" +
        (state.page - 1) * state.pageSize +
        "&limit=" +
        state.pageSize,
      { where: state.whereBoats }
    );
    if (response.data.success) {
      commit("pageBoats", response.data.data);
    } else {
      console.log("No Boats");
      console.log(response.data.msg);
    }
  },
  // Create
  async createBoat({ commit }, contactId, boat) {
    const response = await this.$axios.post(
      "/api/v1/contacts/contact/" + contactId + "/boat",
      boat
    );
    if (response.data.success) {
      commit("setCurrentBoat", response.data.data);
      commit("addBoat");
      return response.data.data.id;
    } else {
      console.log("Create Boat Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getBoat({ commit }, id) {
    const response = await this.$axios.get("/api/v1/contacts/boat/" + id);
    if (response.data.success) {
      commit("setCurrentBoat", response.data.data);
      return true;
    } else {
      console.log("Get Boat Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentBoat", {});
      return false;
    }
  },
  // Update
  async updateBoat({ commit }, updateBoat) {
    const response = await this.$axios.put(
      "/api/v1/contacts/boat/" + state.currentBoat.id,
      state.currentBoat
    );
    if (response.data.success) {
      await commit("setCurrentBoat", updateBoat);
      await commit("updateBoat");
      return true;
    } else {
      console.log("Update Boat Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Delete
  async deleteBoat({ commit }, boatId) {
    const response = await this.$axios.delete(
      "/api/v1/contacts/boat/" + boatId
    );
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeBoat", boatId);
    } else {
      console.log("Delete Boat Error");
      console.log(response.data.msg);
    }
  },

  // - List Contact -
  // List Contacts for select dropdown
  async listContacts({ commit }) {
    const response = await this.$axios.post("/api/v1/contacts/getContacts", {
      where: {},
    });
    if (response.data.success) {
      commit("setContactList", response.data.data);
    } else {
      console.log("No Contacts");
    }
  },
  async listContactBoats({ commit }, contactId) {
    const response = await this.$axios.post(
      "/api/v1/contacts/contact/" + contactId + "/boats",
      { where: {} }
    );
    if (response.data.success) {
      commit("setContactBoatList", response.data.data);
    } else {
      console.log("Contact has no Boats");
    }
  },
};

const mutations = {
  setPage: (state, page) => {
    state.page = page;
  },
  setPageSize: (state, pageSize) => {
    state.pageSize = pageSize;
  },
  setPageSort: (state, pageSort) => {
    state.pageSort = pageSort;
  },
  setPageDir: (state, pageDir) => {
    state.pageDir = pageDir;
  },
  /*
   setWhereContacts: (state, whereContacts) => {
    state.whereContacts = whereContacts
  },
  */
  pageContacts: (state, response) => (
    (state.contacts = response.rows), (state.numberContacts = response.count)
  ),
  pageBoats: (state, response) => (
    (state.boats = response.rows), (state.numberBoats = response.count)
  ),
  // Contacts
  setCurrentContact: (state, response) => (state.currentContact = response),
  addContact: (state) => {
    state.contacts.unshift(state.currentContact);
  },
  updateContact: (state) => {
    const index = state.contacts.findIndex(
      (i) => i.id === state.currentContact.id
    );
    if (index !== -1) {
      state.contacts.splice(index, 1, state.currentContact);
    }
  },
  removeContact: (state, id) => (
    (state.currentContact = {}),
    (state.contacts = state.contacts.filter((contact) => contact.id !== id))
  ),
  // Boats
  setCurrentBoat: (state, response) => (state.currentBoat = response),
  addBoat: (state) => {
    state.boats.unshift(state.currentBoat);
  },
  updateBoat: (state) => {
    const index = state.boats.findIndex((i) => i.id === state.currentBoat.id);
    if (index !== -1) {
      state.boats.splice(index, 1, state.currentBoat);
    }
  },
  removeBoat: (state, id) => (
    (state.currentBoat = {}),
    (state.boats = state.boats.filter((boat) => boat.id !== id))
  ),
  // List Contacts
  setContactList: (state, response) => (state.contactList = response),
  // List Contact Boats
  setContactBoatList: (state, response) => (state.contactBoatList = response),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
