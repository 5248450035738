import Home from "./views/Home";

const routes = [
  // Home
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // Home
  {
    path: "/authdebug",
    name: "AuthDebug",
    component: () => import("./views/AuthDebug"),
  },
  // Login
  {
    path: "/login",
    name: "Login",
    component: () => import("./views/Login"),
    meta: {
      requiresVisitor: true,
    },
  },
  // Contacts
  {
    path: "/contacts",
    name: "ContactsTable",
    component: () => import("./views/contacts/ContactsTable"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacts/boats",
    name: "BoatsTable",
    component: () => import("./views/contacts/BoatsTable"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacts/contact/:id",
    name: "EditContact",
    component: () => import("./views/contacts/EditContact"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacts/contact/:id/boat/:boatId",
    name: "EditBoat",
    component: () => import("./views/contacts/EditBoat"),
    meta: {
      requiresAuth: true,
    },
  },
  // Jobs
  {
    path: "/jobs",
    name: "JobsTable",
    component: () => import("./views/jobs/JobsTable"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jobs/edit/:id",
    name: "EditJob",
    component: () => import("./views/jobs/EditJob"),
    meta: {
      requiresAuth: true,
    },
  },
  // Quotes
  {
    path: "/quotes",
    name: "QuotesTable",
    component: () => import("./views/quotes/QuotesTable"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/quotes/edit/:id",
    name: "EditQuote",
    component: () => import("./views/quotes/EditQuote"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/quoteitem/edit/:id",
    name: "EditQuoteItem",
    component: () => import("./views/quotes/EditQuoteItem"),
    meta: {
      requiresAuth: true,
    },
  },
  // Settings
  {
    path: "/settings",
    name: "Settings",
    component: () => import("./views/settings/Settings"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/loft",
    name: "loft_settings",
    component: () => import("./views/settings/LoftSettings"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/quotedata/table",
    name: "settings_quote_data",
    component: () => import("./views/settings/SettingsQuoteData"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/quotetext/table",
    name: "settings_quote_text",
    component: () => import("./views/settings/SettingsQuoteText"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/riggingitems/table",
    name: "Settings_Rigging_Items",
    component: () => import("./views/settings/SettingsRiggingItems"),
    meta: {
      requiresAuth: true,
    },
  },
  // Rolls
  {
    path: "/rolls",
    name: "RollsTable",
    component: () => import("./views/rolls/RollsTable"),
    meta: {
      requiresAuth: true,
    },
  },
  // Rigging
  {
    path: "/riggingjobs",
    name: "RiggingJobsTable",
    component: () => import("./views/rigging/RiggingJobsTable"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/riggingjob/edit/:id",
    name: "RiggingJobStaysTable",
    component: () => import("./views/rigging/RiggingJobStaysTable"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/riggingjob/list/:id",
    name: "RiggingJobParts",
    component: () => import("./views/rigging/RiggingJobParts"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/riggingitems",
    name: "RiggingItemsTable",
    component: () => import("./views/rigging/RiggingItemsTable"),
    meta: {
      requiresAuth: true,
    },
  },
  // Route Not Found
  {
    path: "*",
    name: "RouteNotFound",
    component: () => import("./views/RouteNotFound"),
  },
];

export default routes;
