<template>
  <b-navbar fixed="top" toggleable="lg" type="light" variant="light">
    <div class="container">
      <b-navbar-brand
        ><i class="fas fa-cube"></i>&nbsp;SailQuoter</b-navbar-brand
      >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <!-- -->
          <router-link to="/" custom v-slot="{ navigate }">
            <b-nav-item
              :class="{ active: $route.name === 'Home' }"
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
              >Home
            </b-nav-item>
          </router-link>
          <!-- -->
          <b-nav-item-dropdown
            v-if="isLoggedIn"
            right
            :class="{
              active:
                $route.name === 'ContactsTable' ||
                $route.name === 'BoatsTable' ||
                $route.name === 'edit_contact' ||
                $route.name === 'edit_boat',
            }"
          >
            <!-- Contacts -->
            <template slot="text"> Contacts </template>
            <router-link to="/contacts" custom v-slot="{ navigate }">
              <b-dropdown-item
                @click="navigate"
                @keypress.enter="navigate"
                role="link"
                >Contacts
              </b-dropdown-item>
            </router-link>
            <router-link to="/contacts/boats" custom v-slot="{ navigate }">
              <b-dropdown-item
                @click="navigate"
                @keypress.enter="navigate"
                role="link"
                >Boats</b-dropdown-item
              >
            </router-link>
          </b-nav-item-dropdown>
          <!-- Jobs -->
          <router-link to="/jobs" custom v-slot="{ navigate }">
            <b-nav-item
              v-if="isLoggedIn"
              :class="{
                active:
                  $route.name === 'JobsTable' || $route.name === 'edit_job',
              }"
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
              >Jobs
            </b-nav-item>
          </router-link>
          <!-- Quotes -->
          <router-link to="/quotes" custom v-slot="{ navigate }">
            <b-nav-item
              v-if="isLoggedIn"
              :class="{
                active: $route.name === 'QuotesTable',
              }"
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
              >Quotes
            </b-nav-item>
          </router-link>
          <!-- Rolls -->
          <router-link to="/rolls" custom v-slot="{ navigate }">
            <b-nav-item
              v-if="isLoggedIn"
              :class="{
                active: $route.name === 'RollsTable',
              }"
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
              >Rolls
            </b-nav-item>
          </router-link>
          <!-- Rigging -->
          <router-link to="/riggingjobs" custom v-slot="{ navigate }">
            <b-nav-item
              v-if="isLoggedIn"
              :class="{
                active: $route.name === 'RiggingJobsTable',
              }"
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
              >Rigging
            </b-nav-item>
          </router-link>
          <!-- -->
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- No user -->
          <router-link to="/login" custom v-slot="{ navigate }">
            <b-nav-item
              v-if="!isLoggedIn"
              :class="{ active: $route.name === 'login' }"
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
              >Login
            </b-nav-item>
          </router-link>
          <!-- Has User -->
          <b-nav-item-dropdown
            v-if="isLoggedIn"
            right
            :class="{ active: $route.name === 'profile' }"
          >
            <template slot="text"> {{ userName }} - {{ loftName }} </template>
            <router-link to="/profile" custom v-slot="{ navigate }">
              <b-dropdown-item
                @click="navigate"
                @keypress.enter="navigate"
                role="link"
                >Profile</b-dropdown-item
              >
            </router-link>
            <router-link to="/settings" custom v-slot="{ navigate }">
              <b-dropdown-item
                @click="navigate"
                @keypress.enter="navigate"
                role="link"
                >Settings</b-dropdown-item
              >
            </router-link>
            <b-dropdown-divider></b-dropdown-divider>
            <router-link to="/authdebug" custom v-slot="{ navigate }">
              <b-dropdown-item
                @click="navigate"
                @keypress.enter="navigate"
                role="link"
                >Auth Debug</b-dropdown-item
              >
            </router-link>
            <b-dropdown-item v-on:click="logout">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userName: "auth/getUserName",
      loftName: "settings/getLoftName",
      isLoggedIn: "auth/isLoggedIn",
    }),
  },
  methods: {
    logout: function () {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style scoped>
.bg-light {
  background-color: #fff !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
li.nav-item.active {
  font-weight: bold;
}
</style>
