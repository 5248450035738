const state = {
  // Table
  page: 1,
  pageSize: 10,
  pageSort: "createdAt",
  pageDir: "ASC",
  // Jobs
  whereJobs: {},
  jobs: [],
  numberJobs: null,
  currentJob: {},
};

const getters = {
  whereJobs: (state) => state.whereJobs,
  allJobs: (state) => state.jobs,
  numberJobs: (state) => state.numberJobs,
  currentJob: (state) => state.currentJob,
};

const actions = {
  // Set Page used in pageJobs
  async setPage({ commit }, page) {
    commit("setPage", page);
  },
  async setPageSize({ commit }, pageSize) {
    commit("setPageSize", pageSize);
  },
  async setPageSort({ commit }, pageSort) {
    commit("setPageSort", pageSort);
  },
  async setPageDir({ commit }, pageDir) {
    commit("setPageDir", pageDir);
  },
  async setWhereJobs({ commit }, whereJobs) {
    commit("setWhereJobs", whereJobs);
  },
  async removeFromWhereJobs({ commit }, key) {
    commit("removeFromWhereJobs", key);
  },
  // - Jobs -
  // Get Contacts for page of table
  async pageJobs({ commit }) {
    var urlParams =
      "?offset=" +
      (state.page - 1) * state.pageSize +
      "&limit=" +
      state.pageSize;
    if (state.pageSort) {
      urlParams += "&sortKey=" + state.pageSort + "&sortDir=" + state.pageDir;
    }
    const response = await this.$axios.post(
      "/api/v1/jobs/findandcountall" + urlParams,
      { where: state.whereJobs }
    );
    if (response.data.success) {
      commit("setJobs", response.data.data);
    } else {
      console.log("No Jobs");
      console.log(response.data.msg);
    }
  },
  // Create
  async createJob({ commit }, job) {
    const response = await this.$axios.post("/api/v1/jobs/job", job);
    if (response.data.success) {
      commit("setCurrentJob", response.data.data);
      commit("addJob");
      return response.data.data.id;
    } else {
      console.log("Create Job Error");
      console.log(response.data.msg);
    }
  },
  // Read
  async getJob({ commit }, id) {
    const response = await this.$axios.get("/api/v1/jobs/job/" + id);
    if (response.data.success) {
      commit("setCurrentJob", response.data.data);
      return true;
    } else {
      console.log("Get Job Error - " + id);
      console.log(response.data.msg);
      commit("setCurrentJob", {});
      return false;
    }
  },
  // Update
  async updateJob({ commit, state }, jobUpdate) {
    const response = await this.$axios.put(
      "/api/v1/jobs/job/" + state.currentJob.id,
      state.currentJob
    );
    if (response.data.success) {
      await commit("setCurrentJob", jobUpdate);
      await commit("updateJob");
      return true;
    } else {
      console.log("Update Job Error");
      console.log(response.data.msg);
      return false;
    }
  },
  // Delete
  async deleteJob({ commit }, jobId) {
    const response = await this.$axios.delete("/api/v1/jobs/job/" + jobId);
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeJob", jobId);
    } else {
      console.log("Delete Job Error");
      console.log(response.data.msg);
    }
  },
  async completeJob({ commit }, jobId) {
    const response = await this.$axios.put("/api/v1/jobs/job/" + jobId, {
      complete: true,
    });
    if (response.data.success) {
      console.log(response);
      commit("completeJob", jobId);
    } else {
      console.log("Complete Job Error");
      console.log(response.data.msg);
    }
  },

  // - Job Files --
  // Create
  async createJobFiles({ commit }, data) {
    const response = await this.$axios.post(
      "/api/v1/jobs/upload/" + state.currentJob.id,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data.success) {
      var items = response.data.data;
      items.forEach((row) => {
        commit("addJobFile", row);
      });
    } else {
      console.log("Create JobFile Error");
      console.log(response.data.msg);
    }
  },
  // Update
  async updateJobFile({ commit }, updJobFile) {
    const response = await this.$axios.put(
      "/api/v1/jobs/file/" + updJobFile.id,
      {
        name: updJobFile.name,
        type: updJobFile.type,
      }
    );
    if (response.data.success) {
      commit("updateJobFile", updJobFile);
    } else {
      console.log("Update JobFile Error");
      console.log(response.data.msg);
    }
  },
  // Delete
  async deleteJobFile({ commit }, jobFileId) {
    const response = await this.$axios.delete("/api/v1/jobs/file/" + jobFileId);
    if (response.data.success) {
      console.log(response.data.msg);
      commit("removeJobFile", jobFileId);
    } else {
      console.log("Delete JobFile Error");
      console.log(response.data.msg);
    }
  },
};

const mutations = {
  setPage: (state, page) => {
    state.page = page;
  },
  setPageSize: (state, pageSize) => {
    state.pageSize = pageSize;
  },
  setPageSort: (state, pageSort) => {
    state.pageSort = pageSort;
  },
  setPageDir: (state, pageDir) => {
    state.pageDir = pageDir;
  },
  setWhereJobs: (state, whereJobs) => {
    var key = Object.keys(whereJobs)[0];
    var val = whereJobs[key];
    state.whereJobs = Object.assign(state.whereJobs, { [key]: val });
  },
  removeFromWhereJobs: (state, key) => {
    delete state.whereJobs[key];
  },
  // Jobs
  setJobs: (state, response) => (
    (state.jobs = response.rows), (state.numberJobs = response.count)
  ),
  setCurrentJob: (state, response) => (state.currentJob = response),
  addJob: (state) => {
    state.jobs.unshift(state.currentJob);
  },
  updateJob: (state) => {
    const index = state.jobs.findIndex((i) => i.id === state.currentJob.id);
    if (index !== -1) {
      state.jobs.splice(index, 1, state.currentJob);
    }
  },
  removeJob: (state, id) => (
    (state.currentJob = {}),
    (state.jobs = state.jobs.filter((job) => job.id !== id))
  ),
  completeJob: (state, id) => {
    const index = state.jobs.findIndex((i) => i.id === id);
    if (index !== -1) {
      state.currentJob = state.jobs[index];
      state.currentJob.complete = true;
      state.jobs.splice(index, 1, state.currentJob);
      state.currentJob = {};
      if (state.whereJobs.complete == false) {
        console.log("state.whereJobs.complete == false");
        state.jobs = state.jobs.filter((job) => job.id !== id);
      }
    }
  },

  // Job Files
  addJobFile: (state, file) => {
    state.currentJob.jobfiles.push(file);
  },
  updateJobFile: (state, updJobFile) => {
    const index = state.currentJob.jobfiles.findIndex(
      (file) => file.id === updJobFile.id
    );
    if (index !== -1) {
      var currentFile = state.currentJob.jobfiles[index];
      currentFile.name = updJobFile.name;
      currentFile.type = updJobFile.type;
      state.currentJob.jobfiles.splice(index, 1, currentFile);
    }
  },
  removeJobFile: (state, id) =>
    (state.currentJob.jobfiles = state.currentJob.jobfiles.filter(
      (file) => file.id !== id
    )),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
